<template>
  <div class="container" ref="outer">
    <div class="pane pane-left" :style="{ width: leftOffsetPercent }">
      <iframe class="exocad_entrance" :src="exocadHref" frameborder="0" v-if="renderType == 'EXO'"></iframe>
      <PreviewDsd v-if="renderType !== 'EXO' && dsdPicList.length" :carouselList="dsdPicList" :isSelected="isSelected" />
    </div>
    <div class="pane pane-right" :style="{ left: leftOffsetPercent }">
      <transition name="slide-fade">
        <div v-if="!isSelected" class="v_c_right posr">
          <div class="tab_header">
            <ul class="v_c_head">
              <li :class="{
                ['li_pt_active']: tabKey === '0',
              }" class="v_c_li" @click="caseDataClick('0')">
                <svg class="icon li_pic" aria-hidden="true">
                  <use v-if="tabKey === '0'" xlink:href="#icon-yijianfankui_xuanzhong"></use>
                  <use v-else xlink:href="#icon-yijianfankui_moren"></use>
                </svg>
                <p :class="{
                  main_theme_color: tabKey === '0',
                }" class="li_pt">
                  {{ $t("threeD.fk") }}
                </p>
              </li>
              <li :class="{
                ['li_pt_active']: tabKey === '1',
              }" class="v_c_li" @click="caseDataClick('1')">
                <svg class="icon li_pic" aria-hidden="true">
                  <use v-if="tabKey === '1'" xlink:href="#icon-lishi_xuanzhong"></use>
                  <use v-else xlink:href="#icon-lishi_moren"></use>
                </svg>
                <p :class="{ main_theme_color: tabKey === '1' }" class="li_pt tac">
                  {{ $t("threeD.lsfa") }}
                </p>
              </li>
              <li :class="{
                ['li_pt_active']: tabKey === '3',
              }" class="v_c_li" @click="caseDataClick('3')">
                <svg class="icon li_pic" aria-hidden="true">
                  <use v-if="tabKey === '3'" xlink:href="#icon-gerenziliao_xuanzhong"></use>
                  <use v-else xlink:href="#icon-gerenziliao_moren"></use>
                </svg>
                <p :class="{ main_theme_color: tabKey === '3' }" class="li_pt">
                  {{
                    isNowEnv
                    ? $t("casesDetail.casesDetail.ddzl")
                    : $t("threeD.blzl")
                  }}
                </p>
              </li>
            </ul>
            <div class="drawer" @click="drawerControl"></div>
          </div>
          <div class="v_c_con" :class="{
            v_c_con_width:
              ['2'].includes(tabKey) || (tabKey === '3' && optionKey === '0'),
          }">
            <div class="close_wrap">
              <div class="flex-y-c">
                <svg class="icon left_icon" aria-hidden="true">
                  <use v-if="tabKey === '1'" xlink:href="#icon-lishi_xuanzhong"></use>
                  <use v-if="tabKey === '2'" xlink:href="#icon-shuoming_xuanzhong"></use>
                  <use v-if="tabKey === '3'" xlink:href="#icon-gerenziliao_xuanzhong"></use>
                </svg>
                <p class="fz18 main_theme_color">
                  {{
                    {
                      "1": $t("threeD.lsfa"),
                      "2": $t("threeD.jzsm"),
                      "3": isNowEnv
                        ? $t("casesDetail.casesDetail.ddzl")
                        : $t("threeD.blzl"),
                    }[tabKey]
                  }}
                </p>
              </div>

              <svg @mousemove="svgColor = true" @mouseleave="svgColor = false" @click="closeCon"
                v-show="['1', '2', '3'].includes(tabKey)" class="icon close curp" aria-hidden="true">
                <use v-if="!svgColor" xlink:href="#icon-guanbi_fanbai"></use>
                <use v-else xlink:href="#icon-guanbi_xuantingdianji"></use>
              </svg>
            </div>
            <div ref="scrollbar" class="scrollbar" style="height: 100%">
              <template v-if="tabKey === '0'">
                <div class="user_message">
                  <div class="user_pic" :style="{
                    backgroundImage: `url(${$PicPrefix}${get(
                      details,
                      'userInfo.photo'
                    )})`,
                  }"></div>
                  <div class="user_content">
                    <p class="user_name txt-ellipsis">
                      {{ get(details, "userInfo.realName") }}
                    </p>
                    <div class="flex-y-c">
                      <svg class="icon user_info_icon" aria-hidden="true">
                        <use xlink:href="#icon-nianling"></use>
                      </svg>
                      <span class="mr10">{{ get(details, "userInfo.age") }}
                        {{ $t("cases.cases.s") }}</span>
                    </div>
                  </div>
                </div>
                <p class="v_c_tit">
                  {{
                    isNowEnv
                    ? $t("casesDetail.casesDetail.ddh")
                    : $t("cases.cases.blh")
                  }}：{{ get(caseDetail, "caseNumber") }}
                </p>
                <p class="v_c_txt">
                  {{ $t("casesDetail.casesDetail.mxfa") }}
                  {{ get(details, "number") }}
                </p>
                <div class="v_c_pTxt_wrap">
                  <el-scrollbar style="height: 100%">
                    <div class="right-content">
                      <div class="v_c_pTxt wsp">
                        {{ schemeExplain }}
                      </div>
                    </div>
                  </el-scrollbar>
                </div>

                <div v-if="buttonShow" class="v_c_foo">
                  <button v-if="amendmentShow" @click="toAmendments()" class="submit_feedback flex-x-y-c curp"
                    style="margin-right: 0.2rem" slot="reference">
                    {{ $t("casesDetail.casesChildren.reviewPlan.tjxgyj") }}
                  </button>
                  <span v-if="approveShow" style="background-color: #02b2b5" class="submit_feedback flex-x-y-c curp"
                    @click="confirmOk">
                    {{ $t("casesDetail.casesChildren.reviewPlan.pzcfa") }}
                  </span>
                </div>
                <p class="scheme_init" v-show="!queryPort">
                  {{ $t("threeD.mnfa") }}
                </p>
              </template>
              <template v-if="tabKey === '1'">
                <ProposalHistory :isOldCase="false" :schemeType="schemeType" @getDetailCurePlan="getDetailCurePlan"
                  :cureType="details.cureType" />
              </template>
              <template v-if="tabKey === '3'">
                <ul class="mh_ul">
                  <li class="mh_li" :class="optionKey === '1' ? 'mh_li_active' : ''">
                    <div class="click_box" @click.stop="changeOptionKey('1')"></div>
                    <p>{{ $t("threeD.zp") }}</p>
                    <p class="mh_len"></p>
                  </li>
                  <li class="mh_li" :class="optionKey === '3' ? 'mh_li_active' : ''">
                    <div class="click_box" @click.stop="changeOptionKey('3')"></div>
                    <p>{{ $t("threeD.qt") }}</p>
                    <p class="mh_len"></p>
                  </li>
                  <li class="mh_li" :class="optionKey === '0' ? 'mh_li_active' : ''">
                    <div class="click_box" @click.stop="changeOptionKey('0')"></div>
                    <p class="tac">{{ $t("threeD.zlb") }}</p>
                    <p class="mh_len"></p>
                  </li>
                </ul>
                <oper-preview :isOldCase="false" v-if="optionKey === '0' && details.id" selectedTabKey="3"
                  :details="details" :caseInfo="caseDetail" :userInfo="userInfo" :teethKeyObj="teethKeyObj"
                  :babyTeethKeyObj="babyTeethKeyObj" :commonTeeth="commonTeeth" :uploadIntervalList="uploadIntervalList"
                  :crowdTeethList="crowdTeethList" :isDark="true" />
                <div class="patient_pic_wrap_bc">
                  <div class="patient_pic_wrap" v-show="optionKey === '1'">
                    <template v-if="patientPictureList.length > 0">
                      <div class="patient_pic curp" v-for="(patient, pax) in patientPictureList" :key="pax">
                        <img @click="
                          showPicFn(patient.name, getPicIndex(patient.filed))
                          " :src="`${$PicPrefix}${patient['nanoId']}`" :style="{
    transform: `rotate(${patient['degree']
      }deg) scaleX(${patient['horizontal'] ? -1 : 1
      }) scaleY(${patient['vertical'] ? -1 : 1})`,
  }" class="patient_pic_item" />
                        <p class="tac">{{ patient.name }}</p>
                      </div>
                    </template>
                    <div class="mon_img" v-else>
                      <img :src="noPicFn()" alt="" />
                      <span class="init_text">{{ $t("threeD.zsmytp") }}</span>
                    </div>
                  </div>
                </div>
                <div class="patient_pic_wrap" v-show="optionKey === '3'">
                  <template v-if="otherPictureList.length > 0">
                    <div class="patient_pic curp" v-for="(item, index) in otherPictureList" :key="index">
                      <img class="patient_pic_item" @click="showPicFn(item.name, item.index)"
                        :src="`${$PicPrefix}${item.nanoId}`" :style="{
                          transform: `rotate(${item['degree']}deg) scaleX(${item['horizontal'] ? -1 : 1
                            }) scaleY(${item['vertical'] ? -1 : 1})`,
                        }" />
                      <p class="tac">{{ item.name }}</p>
                    </div>
                  </template>
                  <div class="mon_img" v-else>
                    <img :src="noPicFn()" alt="" />
                    <span class="init_text">{{ $t("threeD.zsmytp") }}</span>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="fullScreen_v_c_right" v-else>
          <div class="header">
            <div class="drawer" @click="drawerControl"></div>
          </div>
          <ul class="fullScreen_v_c_head">
            <li class="li_item" @click="showCard('0')">
              <svg class="icon li_pic" aria-hidden="true">
                <use v-if="tabKey === '0'" xlink:href="#icon-yijianfankui_xuanzhong"></use>
                <use v-else xlink:href="#icon-yijianfankui_moren"></use>
              </svg>
            </li>
            <li class="li_item" @click="showCard('1')">
              <svg class="icon li_pic" aria-hidden="true">
                <use v-if="tabKey === '1'" xlink:href="#icon-lishi_xuanzhong"></use>
                <use v-else xlink:href="#icon-lishi_moren"></use>
              </svg>
            </li>
            <li class="li_item" @click="showCard('3')">
              <svg class="icon li_pic" aria-hidden="true">
                <use v-if="tabKey === '3'" xlink:href="#icon-gerenziliao_xuanzhong"></use>
                <use v-else xlink:href="#icon-gerenziliao_moren"></use>
              </svg>
            </li>
          </ul>
        </div>
      </transition>
    </div>

    <transition name="slide-fade" v-if="!isSelected">
      <div v-show="amendOpinion" class="amend_opinion" :style="{ left: leftOffsetPercent }">
        <div class="header">
          <p class="header_text">
            <el-tooltip class="item" effect="dark" :content="get(details, 'userInfo.realName')" placement="top-start">
              <span class="real_name">
                {{ get(details, "userInfo.realName") }}</span>
            </el-tooltip>
            <span class="target fz14 main_theme_color">{{ $t("casesDetail.casesDetail.tdzlfa") }}
              {{ get(details, "number") }}</span>
          </p>
          <p class="mt10 c-white">
            {{ get(details, "generateDatetime") }}
          </p>
        </div>
        <p class="opinions_describe">
          <el-scrollbar style="height: 100%">
            <div class="fz14 c-white" style="
                line-height: 0.3rem;
                padding: 0.1rem;
                word-wrap: break-word;
              ">
              {{ schemeExplain }}
            </div>
          </el-scrollbar>
        </p>
        <div class="wire"></div>
        <div class="opinion_title">
          <img src="../../../../common/imgs/3d/opinion_title.png" alt="" />
          <p class="ml10">
            {{ $t("threeD.yjms") }}
          </p>
        </div>
        <div class="posr">
          <el-input class="mt20" type="textarea" :rows="6" :placeholder="$t('threeD.qzcsrxgyj')" v-model="operateRemark">
          </el-input>
          <p v-show="!operateRemark" class="required">
            *{{ $t("threeD.cwbtx") }}
          </p>
        </div>
        <p class="init_text">
          {{ $t("threeD.ntjd") }}
        </p>

        <!-- <p
          v-if="+details.passFlag === 0"
          style="line-height: 18px"
          class="fz12 main_theme_color mt10"
        >
          {{ $t("casesDetail.casesChildren.amendments.zcwrxtj") }}
        </p> -->
        <div class="footer">
          <span class="main_theme_btn_gary main_big_btn" @click="amendOpinion = false">{{
            $t("casesDetail.casesDetail.qx") }}</span>
          <span @click="operatorLogDoctor('2')" class="main_theme_color_btn main_big_btn">
            {{
              $t("casesDetail.casesChildren.amendments.tjxgyj")
            }}
          </span>
        </div>
      </div>
    </transition>

    <confirm-modal :confirmObj="{
          tipText: $t('cases.createDiagnosis.sfpztmfa'),
          backText: $t('casesDetail.casesChildren.common.qx'),
          okText: $t('casesDetail.casesChildren.reviewPlan.rrpz'),
          initText: $t('cases.createDiagnosis.rnpztmfah'),
        }" :showMaterial="false" :isOldCase="false" @confirmSubmit="confirmSubmit()" @confirmHide="confirmHide"
      v-show="isShowConfirm" />
    <notifyMsgCm :isOldCase="false" ref="notifyMsgCm" />
    <el-dialog title="登录超时,页面将会关闭" width="25%" :center="true" :visible.sync="dialogVisible" :before-close="closeWindows">
      <p class="tac fz16rem">如需继续查看，请重新打开页面</p>
      <span slot="footer" class="dialog-footer">
        <el-button style="background-color: #02b2b5" class="main_theme_color_btn submit_btn"
          @click="closeWindows">关闭页面</el-button>
      </span>
    </el-dialog>
    <PreviewImg :carouselList="carouselList" ref="previewImg" @imgChange="imgChange" :notOpacity="true" />
  </div>
</template>

<script>
import $ from "jquery";
import {
  caseCureNoteDetail,
  casesDetail,
  yueyaConfirmCurePlan,
} from "common/api/cases";
import ProposalHistory from "../casesChildren/operationChildren/proposalHistoryCoyp";
import ConfirmModal from "components/confirm-modal-copy/confirm";
import OperPreview from "../casesChildren/operationChildren/operPreview_copy";
import PreviewImg from "components/preview-image/preview-image.vue";
import PreviewDsd from "components/preview-dsd/preview-dsd.vue";
import notifyMsgCm from "../view3d/notify/notifyMsg";
import { mapActions, mapGetters, mapState } from "vuex";
import { each, extend, filter, get, map, pickBy, split } from "lodash";
const adultTeethList = [
  { number: 11 },
  { number: 12 },
  { number: 13 },
  { number: 14 },
  { number: 15 },
  { number: 16 },
  { number: 17 },
  { number: 18 },
  { number: 21 },
  { number: 22 },
  { number: 23 },
  { number: 24 },
  { number: 25 },
  { number: 26 },
  { number: 27 },
  { number: 28 },
  { number: 48 },
  { number: 47 },
  { number: 46 },
  { number: 45 },
  { number: 44 },
  { number: 43 },
  { number: 42 },
  { number: 41 },
  { number: 31 },
  { number: 32 },
  { number: 33 },
  { number: 34 },
  { number: 35 },
  { number: 36 },
  { number: 37 },
  { number: 38 },
];
export default {
  components: {
    ConfirmModal,
    ProposalHistory,
    PreviewImg,
    OperPreview,
    notifyMsgCm,
    PreviewDsd,
  },
  data() {
    return {
      exocadHref: "",
      defaultValue: 0.75,
      value: 0.75,
      triggerWidth: 8,
      min: 0.5,
      max: 0.96,
      canMove: false,
      initOffset: 0,
      isShowRight: true,
      tabKey: "0",
      svgColor: false,
      isSelected: false,
      caseDetail: {},
      details: {},
      schemeExplain: "",
      dialogVisible: false,
      operateRemark: "",
      amendOpinion: false,
      isShowConfirm: false,
      optionKey: "1",
      userInfo: {},
      carouselList: [],
      xLightPictureList: [],
      otherPictureList: [],
      patientPictureList: [],
      uploadIntervalList: [],
      babyTeethKeyObj: {},
      teethKeyObj: {},
      crowdTeethList: [],
      commonTeeth: [],
      dsdPicList: [],
      renderType: "",
    };
  },
  computed: {
    ...mapState({
      language: (state) => state.language,
    }),
    ...mapGetters({
      commonCaseCureDetail: "getCommonCaseCureDetail", //治疗表详情
      commonDiagnosisDetail: "getCommonDiagnosisDetail", // 治疗表资料详情
    }),
    schemeType() {
      return this.$route.query.schemeType;
    },
    buttonShow() {
      if ((!this.$route.query.port || this.$route.query.port === 'liansuo_clinic') && +this.details["passFlag"] === 0) {
        return true;
      }
    },
    approveShow() {
      if (this.$route.query.port === undefined) {
        const status = this.caseDetail["curePlanInfo"].status;
        if (status == "to_doctor_confirm_plan") {
          return true;
        }
      }
    },
    amendmentShow() {
      const status = this.caseDetail["curePlanInfo"].status;
      if (this.$route.query.port === undefined || this.$route.query.port === 'liansuo_clinic') {
        const canShow = ["to_doctor_confirm_plan"];
        if (canShow.includes(status)) {
          return true;
        }
      }
    },
    irdtOperation() {
      return this.$route.query.ts;
    },
    queryPort() {
      return "";
    },
    isNowEnv() {
      return this.$route.query.token;
    },
    //计算左边面板的宽度
    leftOffsetPercent() {
      return `${this.value * 100}%`;
    },
    //右边面板的marginleft
    triggerLeft() {
      return `calc(${this.value * 100}% - ${this.triggerWidth / 2}px)`;
    },
  },
  methods: {
    get,
    ...mapActions({
      getCaseCureDetail: "actionGetCaseCureDetail", //治疗表详情
      getCommonDiagnosisDetail: "actionGetCommonDiagnosisDetail", //治疗表详情
    }),
    showCard(key) {
      this.isSelected = false;
      this.value = this.defaultValue;
      this.tabKey = key;
    },
    getPicIndex(filed) {
      return filter(this.carouselList, (item) => item.filed === filed)[0].index;
    },
    showPicFn(name, key) {
      this.$nextTick(() => {
        this.$refs["previewImg"] && this.$refs["previewImg"].show(name, key);
      });
    },
    imgChange(obj, idx) {
      const current = this.carouselList[idx];
      if (obj.filed !== "otherPicture") {
        if (["xrayCurvePicture", "xraySidePicture"].includes(obj.filed)) {
          this.xLightPictureList.map((it) => {
            if (it.filed == obj.filed) {
              it.nanoId = obj.nanoId;
              it.degree = obj.degree;
              it.horizontal = obj.horizontal;
              it.vertical = obj.vertical;
              it.ETag = obj.ETag;
            }
          });
        } else {
          this.patientPictureList.map((it) => {
            if (it.filed == obj.filed) {
              it.degree = obj.degree;
              it.horizontal = obj.horizontal;
              it.vertical = obj.vertical;
              it.ETag = obj.ETag;
              it.nanoId = obj.nanoId;
            }
          });
        }
      } else {
        this.otherPictureList.map((item) => {
          if (item.key == current.key) {
            this.$set(item, "degree", obj.degree);
            this.$set(item, "horizontal", obj.horizontal);
            this.$set(item, "vertical", obj.vertical);
          }
        });
      }
    },
    changeOptionKey(index) {
      this.optionKey = index;
    },
    closeWindows() {
      window.close();
    },
    getDetailCurePlan(data) {
      return data;
    },
    closeCon() {
      this.tabKey = "0";
    },
    confirmOk() {
      this.isShowConfirm = true;
    },
    toAmendments() {
      this.amendOpinion = !this.amendOpinion;
    },
    confirmHide() {
      this.isShowConfirm = false;
    },
    confirmSubmit() {
      let api = yueyaConfirmCurePlan;
      const curePlanId = this.$route.query.curePlanId;
      if (!curePlanId) {
        this.isShowConfirm = false;
        return false;
      }
      this.isLoading = true;
      api({
        id: curePlanId,
        approveResult: "1",
      })
        .then(() => {
          this.isLoading = false;
          this.isShowConfirm = false;
          this.$refs["notifyMsgCm"]
            .open({
              text: this.$t("casesDetail.casesChildren.common.czcg"),
              type: "success",
            })
            .then(() => {
              setTimeout(() => {
                const { userInfo } = this.details;
                localStorage.setItem(
                  "windowName",
                  userInfo.realName + this.$t("common.common.blxq")
                );
                let query = this.$route.query;
                this.goPage(
                  `/cases/detail?caseId=${query.caseId}&curePlanId=${query.curePlanId}`
                );
              }, 1000);
            });
        })
        .catch(() => {
          this.isLoading = false;
          this.isShowConfirm = false;
        });
    },
    // irdt修改意见
    operatorLogDoctor(type) {
      let query = this.$route.query;
      const curePlanId = query.curePlanId;
      if (curePlanId) {
        if (!this.operateRemark) {
          return this.$refs["notifyMsgCm"].open({
            text: this.$t("casesDetail.casesChildren.amendments.qxtx"),
            type: "warning",
          });
        }
        this.isLoading = true;
        let obj = {
          id: curePlanId,
          approveResult: type,
          approveNote: this.operateRemark,
          token: query.token,
        };
        yueyaConfirmCurePlan(obj)
          .then(() => {
            this.isLoading = false;
            this.$refs["notifyMsgCm"]
              .open({
                text: this.$t("casesDetail.casesChildren.amendments.czcg"),
                type: "success",
              })
              .then(() => {
                const { userInfo } = this.details;
                localStorage.setItem(
                  "windowName",
                  userInfo.realName + this.$t("common.common.blxq")
                );
                if (this.$route.query.port === 'liansuo_clinic') {
                  this.amendOpinion = false;
                  return this.getDetail();
                } else {
                  this.goPage("/cases/detail");
                }
              });
          })
          .catch(() => {
            this.isLoading = false;
          });
      } else {
        return this.$refs["notifyMsgCm"].open({
          text: this.$t("casesDetail.casesChildren.amendments.czsb"),
          type: "error",
        });
      }
    },
    goPage(url) {
      let query = this.$route.query;
      sessionStorage.removeItem("hideHead");
      return (
        url &&
        this.$router.push({
          path: url,
          query: extend(
            {},
            { caseId: query.caseId, curePlanId: query.curePlanId }
          ),
        })
      );
    },
    drawerControl() {
      if (this.isSelected) {
        this.value = this.defaultValue;
        $(".v_c_right").addClass("fullScreen_right");
      } else {
        this.value = this.max;
        $(".v_c_right").removeClass("fullScreen_right");
      }
      this.isSelected = !this.isSelected;
    },
    caseDataClick(index) {
      this.tabKey = index;
      this.amendOpinion = false;
    },
    //鼠标点击  鼠标移动事件  计算偏差
    handleMousedown(event) {
      $(".exocad_entrance").addClass("forbid_event");
      document.addEventListener("mousemove", this.handleMousemove);
      document.addEventListener("mouseup", this.handleMouseup);
      this.initOffset =
        event.pageX - event.srcElement.getBoundingClientRect().left;
      this.canMove = true;
    },
    //鼠标移动事件 计算移动距离
    handleMousemove(event) {
      if (!this.canMove) return;
      const outerRect = this.$refs.outer.getBoundingClientRect();
      let offsetPercent =
        (event.pageX -
          this.initOffset +
          this.triggerWidth / 2 -
          outerRect.left) /
        outerRect.width;
      if (offsetPercent < this.min) {
        offsetPercent = this.min;
      }
      if (offsetPercent > this.max) {
        offsetPercent = this.max;
        this.isSelected = true;
        $(".v_c_right").addClass("fullScreen_right");
      } else if (offsetPercent < this.max) {
        this.isSelected = false;
        $(".v_c_right").removeClass("fullScreen_right");
      }
      this.value = offsetPercent;
    },
    handleMouseup() {
      $(".exocad_entrance").removeClass("forbid_event");
      this.canMove = false;
    },
    async getDetail() {
      let { cureNoteId, token, caseId } = this.$route.query;
      let cure = await caseCureNoteDetail(
        token ? { id: cureNoteId, token } : cureNoteId
      );
      this.renderType = cure.attachmentFileType;
      this.dsdPicList = cure.attachmentList;
      this.exocadHref = `${this.$PicPrefix}${cure.toothView}`;
      await this.getCommonDiagnosisDetail(
        token ? { id: cure.diagnosisId, token } : cure.diagnosisId
      ).catch((err) => {
        this.dialogVisible = true;
      });
      let data = extend({}, cure, this.commonDiagnosisDetail.detail);
      this.details = data;
      const {
        toothInfoList,
        cutInfoList = [],
        caseInfo = {},
        otherPicture = [],
        status,
        toothMovement = "",
        toothView,
        toothViewNewFlag,
        sideFacePicture,
        frontFacePicture,
        smilePicture,
        bitePicture,
        openAndClosePicture,
      } = data;
      this.schemeExplain = data.note;
      this.userInfo = data.userInfo;
      this.carouselList = [
        sideFacePicture
          ? {
            href: this.$PicPrefix + this.isJSON(sideFacePicture).ETag,
            filed: "sideFacePicture",
            name: this.$t("cases.createImage.zcmwxx"),
            ETag: this.isJSON(sideFacePicture).ETag,
            nanoId:
              this.isJSON(sideFacePicture).nanoId ||
              this.isJSON(sideFacePicture).ETag,
            degree: this.isJSON(sideFacePicture).degree,
            horizontal: this.isJSON(sideFacePicture).horizontal,
            vertical: this.isJSON(sideFacePicture).vertical,
          }
          : "",
        frontFacePicture
          ? {
            href: this.$PicPrefix + this.isJSON(frontFacePicture).ETag,
            filed: "frontFacePicture",
            name: this.$t("cases.createImage.zmwxx"),
            ETag: this.isJSON(frontFacePicture).ETag,
            nanoId:
              this.isJSON(frontFacePicture).nanoId ||
              this.isJSON(frontFacePicture).ETag,
            degree: this.isJSON(frontFacePicture).degree,
            horizontal: this.isJSON(frontFacePicture).horizontal,
            vertical: this.isJSON(frontFacePicture).vertical,
          }
          : "",
        smilePicture
          ? {
            href: this.$PicPrefix + this.isJSON(smilePicture).ETag,
            filed: "smilePicture",
            name: this.$t("cases.createImage.ycmwxx"),
            ETag: this.isJSON(smilePicture).ETag,
            nanoId:
              this.isJSON(smilePicture).nanoId ||
              this.isJSON(smilePicture).ETag,
            degree: this.isJSON(smilePicture).degree,
            horizontal: this.isJSON(smilePicture).horizontal,
            vertical: this.isJSON(smilePicture).vertical,
          }
          : "",
        bitePicture
          ? {
            href: this.$PicPrefix + this.isJSON(bitePicture).ETag,
            filed: "bitePicture",
            name: this.$t("cases.createImage.yhx"),
            ETag: this.isJSON(bitePicture).ETag,
            nanoId:
              this.isJSON(bitePicture).nanoId ||
              this.isJSON(bitePicture).ETag,
            degree: this.isJSON(bitePicture).degree,
            horizontal: this.isJSON(bitePicture).horizontal,
            vertical: this.isJSON(bitePicture).vertical,
          }
          : "",
        openAndClosePicture
          ? {
            href: this.$PicPrefix + this.isJSON(openAndClosePicture).ETag,
            filed: "openAndClosePicture",
            name: this.$t("cases.createImage.khx"),
            ETag: this.isJSON(openAndClosePicture).ETag,
            nanoId:
              this.isJSON(openAndClosePicture).nanoId ||
              this.isJSON(openAndClosePicture).ETag,
            degree: this.isJSON(openAndClosePicture).degree,
            horizontal: this.isJSON(openAndClosePicture).horizontal,
            vertical: this.isJSON(openAndClosePicture).vertical,
          }
          : "",
      ].filter((item) => !!item.href);
      this.carouselList = [
        ...this.carouselList,
        ...map(this.fileList, (item) => {
          return {
            href: this.$PicPrefix + this.isJSON(item).ETag,
            filed: "otherPicture",
            name: this.$t("cases.createImage.yxzl"),
          };
        }),
      ];
      if (otherPicture.length) {
        try {
          JSON.parse(otherPicture);
          this.otherPictureList = map(
            JSON.parse(otherPicture),
            (item, index) => {
              return {
                name: this.$t(
                  "casesDetail.casesChildren.operationChildren.yxzl"
                ),
                href: this.$PicPrefix + item.ETag,
                key: index,
                filed: "otherPicture",
                ETag: item.ETag,
                nanoId: item.nanoId || item.ETag,
                degree: item.degree,
                horizontal: item.horizontal,
                vertical: item.vertical,
              };
            }
          );
        } catch (error) {
          this.otherPictureList = map(
            split(otherPicture, ","),
            (item, index) => {
              return {
                name: this.$t(
                  "casesDetail.casesChildren.operationChildren.yxzl"
                ),
                href: this.$PicPrefix + item,
                ETag: item,
                nanoId: item,
                filed: "otherPicture",
                degree: 0,
                horizontal: false,
                vertical: false,
              };
            }
          );
        }
      }
      this.carouselList = [...this.carouselList, ...this.otherPictureList];

      this.carouselList = map(this.carouselList, (item, index) => {
        return extend({}, item, { index: index + "" });
      });
      this.patientPictureList = filter(this.carouselList, (item) =>
        [
          "sideFacePicture",
          "frontFacePicture",
          "smilePicture",
          "bitePicture",
          "openAndClosePicture",
        ].includes(item.filed)
      );
      this.otherPictureList = filter(
        this.carouselList,
        (item) => item.filed === "otherPicture"
      );
      if (Array.isArray(toothInfoList)) {
        const babyObj = {};
        const missingObj = {}; // missingTeethKeyObj
        const noMoveObj = {}; // noMoveTeethKeyObj
        const noDesignObj = {}; // noDesignTeethKeyObj
        const extractionObj = {}; // extractionTeethKeyObj
        const attachmentObj = {}; // attachmentTeethKeyObj
        toothInfoList.forEach((item) => {
          if (item.type === "1") {
            missingObj[item.number] = {
              type: "1",
              number: item.number,
              className: "teeth_missing",
            };
          } else if (item.type === "2") {
            noMoveObj[item.number] = {
              type: "2",
              number: item.number,
              className: "teeth_no_move",
            };
          } else if (item.type === "3") {
            noDesignObj[item.number] = {
              type: "3",
              number: item.number,
              className: "teeth_no_design",
            };
          } else if (item.type === "4") {
            extractionObj[item.number] = {
              type: "4",
              number: item.number,
              className: "teeth_extraction",
            };
          } else if (item.type === "6") {
            attachmentObj[item.number] = {
              type: "6",
              number: item.number,
              className: "t__s_tag_fj",
            };
          } else if (item.type === "7") {
            babyObj[item.number] = {
              id: item.id,
              type: "7",
              number: item.number,
            };
          }
        });
        this.teethKeyObj = {
          missingTeethKeyObj: missingObj,
          noMoveTeethKeyObj: extend({}, missingObj, noMoveObj),
          noDesignTeethKeyObj: extend({}, missingObj, noDesignObj),
          extractionTeethKeyObj: extend(
            {},
            missingObj,
            extractionObj,
            noMoveObj
          ),
        };
        this.babyTeethKeyObj = babyObj;
      }

      if (Array.isArray(cutInfoList)) {
        let obj = {};
        cutInfoList.forEach((item) => {
          obj[+item.toothEnd] = {
            ...item,
          };
        });
        this.cutInfoObj = obj;
      }

      let lossTeeth = filter(data.toothInfoList, (item) => item.type === "1");
      let dentureTeeth = filter(
        data.toothInfoList,
        (item) => item.type === "11"
      );
      let veneerTeeth = filter(
        data.toothInfoList,
        (item) => item.type === "10"
      );
      let crownTeeth = filter(data.toothInfoList, (item) => item.type === "12");
      let inlayTeeth = filter(data.toothInfoList, (item) => item.type === "13");
      let grindTeeth = filter(data.toothInfoList, (item) => item.type === "14");
      let untreatedList = [
        ...lossTeeth,
        ...dentureTeeth,
        ...veneerTeeth,
        ...crownTeeth,
        ...inlayTeeth,
        ...grindTeeth,
      ];
      let mainList = adultTeethList;
      if (untreatedList.length) {
        let teethTypeObj = {
          1: "LOSS", // 缺失牙
          10: "VENEER", // 贴面
          11: "DENTURE", // 假牙
          12: "CROWN", // 冠
          13: "INLAY", // 嵌体
          14: "GRIND", // 备牙
        };
        this.commonTeeth = filter(
          map(mainList, (item) => {
            let obj;
            each(untreatedList, (it) => {
              if (item.number === it.number) {
                obj = extend({}, item, obj, {
                  [teethTypeObj[it.type]]: teethTypeObj[it.type],
                });
              }
            });
            return obj ? obj : item;
          })
        );
      } else {
        this.commonTeeth = [];
      }
    },
    noPicFn() {
      return (
        {
          clinic: require("../../../../common/imgs/3d/blue_zanwu.png"),
          irdt: require("../../../../common/imgs/3d/purple_zanwu.png"),
          admin: require("../../../../common/imgs/3d/blue_zanwu.png"),
        }[this.queryPort] ||
        require("../../../../common/imgs/3d/view_empty.png")
      );
    },
    isJSON(str) {
      if (str == "") {
        return "";
      }
      if (typeof str == "string") {
        try {
          JSON.parse(str);
          let obj = JSON.parse(str);
          !obj.nanoId && (obj.nanoId = obj.ETag);
          !obj.degree && (obj.degree = 0);
          !obj.horizontal && (obj.horizontal = false);
          !obj.vertical && (obj.vertical = false);
          return obj;
        } catch (error) {
          return {
            ETag: str,
            nanoId: str,
            degree: 0,
            horizontal: false,
            vertical: false,
          };
        }
      }
    },
  },
  created() {
    let { token, caseId } = this.$route.query;
    casesDetail(token ? { id: caseId, token } : caseId).then(res => {
      this.caseDetail = res;
    })
    this.getDetail();
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;

  .pane {
    position: absolute;
    top: 0;
    height: 100%;

    &-left {
      background: transparent;
      box-sizing: border-box;
      background: rgba(46, 47, 48, 0.9);
    }

    &-right {
      box-sizing: border-box;
      right: 0;
      bottom: 0;
      background: transparent;
    }

    &-trigger-con {
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 10;
      user-select: none;
      cursor: col-resize;
      z-index: 997;

      .pane-trigger-con__button {
        width: 4px;
        height: 160px;
        margin: 0 auto;
        position: relative;
        background: #ffffff;
        top: 50%;
        /*偏移*/
        transform: translateY(-50%);
        border-radius: 2px;
      }
    }
  }

  .exocad_entrance {
    width: 100%;
    height: 100%;
  }

  .forbid_event {
    pointer-events: none;
  }
}

.v_c_right {
  z-index: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
  height: 100%;

  .tab_header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 20px;
    height: 68px;
    background-color: #38383a;
    align-items: center;
  }

  .v_c_head {
    display: flex;
    height: 68px;

    .v_c_li {
      width: 124px;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      font-size: 0.14rem;
      cursor: pointer;
      position: relative;
      background-color: #38383a;
      transition: all 0.2s;
    }

    .v_c_li_old {
      background: #f8f8f8;
    }

    .v_c_li_active {
      background-color: #454647;
      transition: all 0.2s;
    }

    /*.v_c_li_new{*/
    /*margin-right: 0.5rem;*/
    /*}*/
    .li_pic {
      width: 0.34rem;
      height: 0.34rem;
      margin-top: 6px;
    }

    .li_pic_y {
      background-image: url("../../../../common/imgs/3d/yachi_icon.png");
    }

    .li_pic_f {
      background-image: url("../../../../common/imgs/3d/lishi_icon.png");
    }

    .li_pic_j {
      background-image: url("../../../../common/imgs/3d/caozuo_icon.png");
    }

    .li_pic_b {
      background-image: url("../../../../common/imgs/3d/shiyitu_icon.png");
    }

    .li_pt {
      line-height: 0.14rem;
      color: $main_theme_color_999;
      transition: all 0.2s;
    }

    .li_pt_active {
      background-color: #454647;
      color: $main_theme_color !important;
      transition: all 0.2s;
    }

    .li_pt_active_old {
      color: $main_theme_color !important;
      background-color: white;
      transition: all 0.2s;
    }
  }

  .v_c_head_old {
    background: #ffffff;
  }

  .v_c_con {
    padding-left: 0.3rem;
    padding-bottom: 0.6rem;
    height: 100%;
    color: #ffffff;
    width: 100%;
    box-sizing: border-box;
    background-color: #454647;
    transition: all 0.2s;
    position: relative;

    .patient_pic_wrap {
      width: 100%;
      display: flex;
      align-items: center;
      flex-flow: row wrap;
      margin-top: 20px;
      padding-bottom: 40px;
      overflow: hidden;

      .mon_img {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;

        img {
          display: block;
          width: 2.04rem;
          margin-top: 2rem;
        }

        .init_text {
          margin-top: 0.2rem;
          font-size: 0.18rem;
        }
      }

      .patient_pic {
        width: 100%;
        border-radius: 8px;
        margin-bottom: 30px;
        position: relative;
        overflow: hidden;

        .patient_pic_item {
          display: block;
          width: 100%;
        }

        p {
          width: 100%;
          height: 0.3rem;
          background-color: rgba($color: #2e2f30, $alpha: 1);
          z-index: 22;
          color: rgba($color: #ffffff, $alpha: 1);
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom-right-radius: 8px;
          border-bottom-left-radius: 8px;
        }
      }
    }

    .user_message {
      width: 100%;
      height: 0.88rem;
      display: flex;
      margin-top: 3px;

      .user_pic {
        width: 0.88rem;
        height: 0.88rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin-right: 0.19rem;
        border-radius: 6px;
      }

      .user_content {
        flex: 1;

        .user_name {
          width: 2.3rem;
          height: 25px;
          font-size: 0.2rem;
          font-weight: 500;
          color: #ffffff;
          margin-bottom: 15px;
        }

        .user_info_icon {
          width: 24px;
          height: 26px;
        }

        .city_name {
          max-width: 2.2rem;
          line-height: 20px;
        }
      }
    }

    .v_c_tit {
      margin-top: 30px;
      font-size: 0.18rem;
      margin-bottom: 0.1rem;
    }

    .v_c_txt {
      font-size: 0.18rem;
      margin-bottom: 0.1rem;
    }

    .v_c_texture {
      font-size: 0.18rem;
      line-height: 0.24rem;
      margin-bottom: 0.4rem;
    }

    .v_c_pTxt_wrap {
      width: 98.9%;
      border-radius: 0.05rem;
      border: 1px solid #bbbbbb;
      height: 3rem;
      padding: 10px 16px;

      .v_c_pTxt {
        height: 100%;
        line-height: 0.3rem;
        word-wrap: break-word;

        .c_p_left {
          margin-right: 0.1rem;
          margin-top: 0.06rem;
          line-height: 0.32rem;
          font-size: 0.18rem;
          font-weight: 500;
        }
      }
    }
  }

  .v_c_con_old {
    background: #ffffff;
  }

  .v_c_con_width {
    width: 14.3rem !important;
    height: 100%;
    transition: all 0.2s;
  }

  .v_c_foo {
    width: 98.9%;
    margin-top: 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .f_img {
    position: fixed;
    top: 5%;
    left: 0.6%;
    width: 13%;

    img {
      width: 100%;
      display: block;
    }
  }

  .scheme_init {
    margin-top: 0.17rem;
    width: 100%;
    line-height: 0.25rem;
    font-size: 0.14rem;
    display: flex;
    flex-direction: column-reverse;
    color: white;
  }
}

.fullScreen_v_c_right {
  width: 100%;
  height: 100%;
  background-color: #38383a;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .drawer {
    transform: rotateZ(180deg);
  }

  .fullScreen_v_c_head {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    border-top: 1px solid rgba(229, 229, 229, 0.24);
    align-items: center;

    .li_item {
      width: 32px;
      height: 32px;
      margin-bottom: 32px;
    }

    .li_pic {
      width: 32px;
      height: 32px;
      margin-top: 6px;
    }

    .li_pic_y {
      background-image: url("../../../../common/imgs/3d/yachi_icon.png");
    }

    .li_pic_f {
      background-image: url("../../../../common/imgs/3d/lishi_icon.png");
    }

    .li_pic_j {
      background-image: url("../../../../common/imgs/3d/caozuo_icon.png");
    }

    .li_pic_b {
      background-image: url("../../../../common/imgs/3d/shiyitu_icon.png");
    }
  }
}

.drawer {
  width: 24px;
  height: 24px;
  background-image: url(../../../../common/imgs/exocad.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;

  &:hover {
    background-image: url(../../../../common/imgs/exocad_active.png);
  }
}

.close_wrap {
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  .left_icon {
    width: 32px;
    height: 32px;
    margin-left: -0.04rem;
  }

  .close {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 30px;
  }
}

.submit_feedback {
  width: 176px;
  height: 42px;
  background-color: #fff;
  color: #333333;
  font-size: 0.16rem;
  border-radius: 6px;
  text-align: center;
}

.main_theme_btn_gary {
  font-size: 16px;
  background-color: #555657;
  color: white;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 5px;
  cursor: pointer !important;
  margin-right: 32px;
}

.amend_opinion {
  position: absolute;
  bottom: 0;
  top: 0.68rem;
  right: 0;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  background: #393b3c;
  transition: all 0.3s;
  padding: 20px 30px;
  z-index: 999;
  border-radius: 6px;
  border: 1px solid #666666;

  .header {
    width: 100%;

    .header_text {
      display: flex;
      align-items: center;
      color: white;

      .real_name {
        display: inline-block;
        max-width: 1.5rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 0.2rem;
        margin-right: 0.2rem;
      }
    }
  }

  .footer {
    width: 100%;
    margin-top: 30px;
    display: flex;

    .amend_opinion_cancel {
      width: 124px;
      height: 36px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #bbbbbb;
    }

    .amend_opinion_confirm {
      width: 124px;
      height: 36px;
      background: $main_theme_color;
      border-radius: 4px;
    }
  }

  .opinions_describe {
    width: 100%;
    height: 140px;
    border-radius: 6px;
    background: #58595a;
    border: 1px solid #666666;
    margin-top: 20px;
  }

  .opinions_describe_old {
    background: #f4f4f4;
    border: 1px solid #f4f4f4;

    /deep/ .el-textarea__inner {
      background: #f4f4f4 !important;
      color: $main_theme_color_333 !important;
    }
  }

  .opinion_title {
    display: flex;
    align-items: center;
    color: white;
  }

  .wire {
    width: 100%;
    height: 0.01rem;
    background-color: white;
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .required {
    color: #ed4027;
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-size: 12px;
  }

  .init_text {
    margin-top: 10px;
    line-height: 0.25rem;
    color: white;
    font-size: 0.12rem;
  }
}

.scrollbar {
  overflow-y: auto;
  padding-right: 30px;

  &::-webkit-scrollbar-thumb {
    background-color: rgba(#101f1c, 0.5);
  }
}

.mh_ul {
  width: 100%;
  height: 40px;
  display: flex;
  position: absolute;
  top: 0.38rem;
  background-color: #454647;
  z-index: 9999;
  padding-top: 13px;

  .mh_li {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.14rem;
    margin-right: 25px;
    white-space: nowrap;
    cursor: pointer;
    position: relative;
    transition: all 0.2s;

    .mh_len {
      height: 0.02rem;
      margin-top: 0.08rem;
      width: 100%;
      border-radius: 0.03rem;
      background-color: transparent;
      transition: all 0.2s;
    }
  }

  .mh_li_active {
    transition: all 0.2s;
    color: $main_theme_color;

    .mh_len {
      background-color: $main_theme_color;
      transition: all 0.2s;
    }
  }

  .mh_li_four {
    position: relative;
    top: -14px;
  }
}

/deep/ .el-scrollbar__wrap {
  overflow-x: hidden;
  overflow-y: auto;
}

/deep/ .el-textarea__inner {
  width: 100.5%;
  resize: none;
  padding-bottom: 20px;
  background-color: #393b3c;
  color: white !important;
}

/deep/ .el-textarea__inner:focus {
  border: 1px solid #bbbbbb;
}

/deep/ .right-content .v_c_pTxt .c_p_left {
  line-height: 0.25rem;
}
</style>