var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"proposal_history"},[(_vm.operatorLog.length)?_c('ul',{ref:"his_Ul",staticClass:"his_ul"},_vm._l((_vm.operatorLog),function(item,index){return _c('li',{key:item.id,staticClass:"his_single",class:'his_single' + item.id},[_c('div',{staticClass:"his_right"},[_c('div',{staticClass:"flex-x-between"},[_c('h5',{staticClass:"his_r_h5"},[_c('span',{staticClass:"his_h_icon"},[_vm._v(_vm._s(_vm.operatorLog.length - index))]),_c('span',{staticClass:"note_name",class:{ main_theme_color_333: _vm.isOldCase }},[_vm._v(_vm._s(item.noteName))])]),(+item.id !== 0)?_c('div',{staticClass:"curp fz14",class:{ main_theme_color: _vm.isOldCase },style:(("color: " + (+item.id === +_vm.$route.query.cureNoteId ? '#02b2b5' : 'white'))),on:{"click":function($event){return _vm.openNewThreeD(item)}}},[_vm._v(" "+_vm._s(+item.id === +_vm.$route.query.cureNoteId ? _vm.$t("casesDetail.casesChildren.textPlanChildren.dqfa") : _vm.$t("cases.createImage.ck"))+" ")]):_vm._e()]),_c('p',{staticClass:"create_time",class:{ main_theme_color_666: _vm.isOldCase }},[_vm._v(" "+_vm._s(item.generateDatetime)+" ")]),_c('div',{staticClass:"his_r_box"},[(item.specialInstructions || item.note || item.cureSuggestList)?_c('div',{staticClass:"his_r_box_bc",class:{ his_r_box_bc_old: _vm.isOldCase }},[(item.specialInstructions)?_c('div',[_c('p',{staticClass:"doctor_opinion",class:{ main_theme_color_333: _vm.isOldCase }},[_vm._v(" "+_vm._s(_vm.$t("casesDetail.casesChildren.operationChildren.ysjy"))+"： ")]),_c('p',{staticClass:"special_explain wsp",class:{ special_explain_old: _vm.isOldCase }},[_vm._v(" "+_vm._s(item.specialInstructions)+" ")])]):_vm._e(),(item.note)?_c('div',{staticClass:"mb16",class:{
              mt10: item.specialInstructions,
            }},[_c('p',{staticClass:"suggest",class:{
                main_theme_color_333: _vm.isOldCase,
              }},[_vm._v(" "+_vm._s(_vm.$t("casesDetail.casesChildren.operationChildren.ylkjjy"))+": ")]),_c('p',{staticClass:"honorific_doctor wsp",class:{
                    honorific_doctor_old: _vm.isOldCase,
                  }},[_vm._v(" "+_vm._s(item.note)+" ")])]):_vm._e(),(item.cureSuggestList && item.cureSuggestList.length)?_c('div',_vm._l((item.cureSuggestList),function(it,idx){return _c('div',{key:idx,staticClass:"his_r__yj",class:{ his_r__yj_old: _vm.isOldCase }},[_c('div',{staticClass:"yj_left"},[_c('div',{staticClass:"wrap_suggest",class:{
                    mb16: idx !== item.cureSuggestList.length - 1,
                    wrap_suggest_old: _vm.isOldCase,
                  }},[_c('div',{staticClass:"yj_left_p mb4 flex-x-between"},[_c('div',{staticClass:"operator_node fz14 txt-ellipsis",attrs:{"title":_vm.dictionariesObj[it.operatorNode]}},[_vm._v(" "+_vm._s(_vm.dictionariesObj[it.operatorNode] ? _vm.dictionariesObj[it.operatorNode] : it.suggest)+" ")]),_c('div',{staticClass:"fz12 operator_create_date_time",class:{ ml10: !!_vm.dictionariesObj[it.operatorNode] }},[_vm._v(" "+_vm._s(it.createDatetime)+" ")])]),(_vm.dictionariesObj[it.operatorNode])?_c('p',{staticClass:"yj_left_suggest"},[_vm._v(_vm._s(it.suggest))]):_vm._e()])])])}),0):_vm._e()]):_vm._e()])])])}),0):_vm._e(),(!_vm.operatorLog.length && _vm.operatorLogState)?_c('div',{staticClass:"no_his"},[_c('div',{staticClass:"no_his_pic",class:_vm.renderClass}),_c('p',{staticClass:"c-white"},[_vm._v(" "+_vm._s(_vm.$t("casesDetail.casesChildren.operationChildren.zsmylsfa"))+"～ ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }