<template>
  <div class="proposal_history">
    <ul ref="his_Ul" class="his_ul" v-if="operatorLog.length">
      <li class="his_single" :class="'his_single' + item.id" v-for="(item, index) in operatorLog" :key="item.id">
        <div class="his_right">
          <div class="flex-x-between">
            <h5 class="his_r_h5">
              <span class="his_h_icon">{{ operatorLog.length - index }}</span>
              <span :class="{ main_theme_color_333: isOldCase }" class="note_name">{{ item.noteName }}</span>
            </h5>
            <div :class="{ main_theme_color: isOldCase }" :style="`color: ${+item.id === +$route.query.cureNoteId ? '#02b2b5' : 'white'
              }`" v-if="+item.id !== 0" @click="openNewThreeD(item)" class="curp fz14">
              {{
                +item.id === +$route.query.cureNoteId
                ? $t("casesDetail.casesChildren.textPlanChildren.dqfa")
                : $t("cases.createImage.ck")
              }}
            </div>
          </div>
          <p :class="{ main_theme_color_666: isOldCase }" class="create_time">
            {{ item.generateDatetime }}
          </p>

          <div class="his_r_box">
            <div v-if="item.specialInstructions || item.note || item.cureSuggestList"
              :class="{ his_r_box_bc_old: isOldCase }" class="his_r_box_bc">
              <div v-if="item.specialInstructions">
                <p :class="{ main_theme_color_333: isOldCase }" class="doctor_opinion">
                  {{ $t("casesDetail.casesChildren.operationChildren.ysjy") }}：
                </p>
                <p :class="{ special_explain_old: isOldCase }" class="special_explain wsp">
                  {{ item.specialInstructions }}
                </p>
              </div>
              <div :class="{
                mt10: item.specialInstructions,
              }" class="mb16" v-if="item.note">
                <p :class="{
                  main_theme_color_333: isOldCase,
                }" class="suggest">
                  {{
                    $t("casesDetail.casesChildren.operationChildren.ylkjjy")
                  }}:
                </p>
                <p :class="{
                      honorific_doctor_old: isOldCase,
                    }" class="honorific_doctor wsp">
                  {{ item.note }}
                </p>
              </div>
              <div v-if="item.cureSuggestList && item.cureSuggestList.length">
                <div :class="{ his_r__yj_old: isOldCase }" v-for="(it, idx) in item.cureSuggestList" :key="idx"
                  class="his_r__yj">
                  <div class="yj_left">
                    <div :class="{
                      mb16: idx !== item.cureSuggestList.length - 1,
                      wrap_suggest_old: isOldCase,
                    }" class="wrap_suggest">
                      <div class="yj_left_p mb4 flex-x-between">
                        <div :title="dictionariesObj[it.operatorNode]" class="operator_node fz14 txt-ellipsis">
                          {{
                            dictionariesObj[it.operatorNode]
                            ? dictionariesObj[it.operatorNode]
                            : it.suggest
                          }}
                        </div>
                        <div :class="{ ml10: !!dictionariesObj[it.operatorNode] }" class="fz12 operator_create_date_time">
                          {{ it.createDatetime }}
                        </div>
                      </div>
                      <p v-if="dictionariesObj[it.operatorNode]" class="yj_left_suggest">{{ it.suggest }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="no_his" v-if="!operatorLog.length && operatorLogState">
      <div class="no_his_pic" :class="renderClass"></div>
      <p class="c-white">
        {{ $t("casesDetail.casesChildren.operationChildren.zsmylsfa") }}～
      </p>
    </div>
  </div>
</template>

<script>
import { cureSuggestList } from "common/api/cases";
import { splitText } from "common/js/util";
import { detailCurePlan } from "common/api/cases";
import { get, each } from "lodash";
import { getDictList } from "common/api/public";

export default {
  data() {
    return {
      operatorLog: [],
      operatorLogState: "",
      dictionariesObj: {},
    };
  },
  props: {
    cureSummary: {
      type: Object,
      default: () => ({}),
    },
    schemeType: {
      type: String,
    },
    isOldCase: {
      type: Boolean,
      default: false,
    },
    cureType: {
      required: false,
    },
  },
  computed: {
    queryPort() {
      return this.$route.query.port;
    },
    renderClass() {
      switch (this.queryPort) {
        case "admin":
          return "blue_pic";
        case "clinic":
          return "blue_pic";
        case "irdt":
          return "purple_pic";
        default:
          return "yellow_pic";
      }
    },
  },
  methods: {
    get,
    openNewThreeD(item) {
      if (+item.id === +this.$route.query.cureNoteId) return;
      let obj = this.$route.query;
      let str = "";
      for (let i in obj) {
        str += `${i}=${i === "cureNoteId" ? item.id : obj[i]}&`;
      }
      if (this.cureType === "F") {
        window.open("/exocad?" + str, "_blank");
      } else {
        window.open("/cases/view3d?" + str, "_blank");
      }
    },
    getList() {
      let { caseId, token } = this.$route.query;
      cureSuggestList({ caseId, token })
        .then((logList) => {
          this.operatorLog = logList;
          let wrap = document.querySelector(".mo_con");
          let number = 0;
          let list = [];
          each(this.operatorLog, (item, index) => {
            if (+item.id === +this.$route.query.cureNoteId) {
              list = this.operatorLog.slice(0, index);
            }
          });
          this.operatorLogState = "loadend";
          each(list, (item) => {
            number += document.querySelector(
              `.his_single${item.id}`
            ).offsetHeight;
          });
          wrap.scrollTop = number;
        })
        .catch(() => {
          this.operatorLogState = "loaderr";
        });
    },
    getSuggestDictionaries() {
      getDictList("cure_suggest.node").then((data) => {
        each(data, (item) => {
          this.dictionariesObj[item.key] = item.value;
        });
        this.getList();
      });
    },
  },
  created() {
    this.getSuggestDictionaries();
  },
};
</script>

<style scoped lang="scss">
.proposal_history {
  width: 100%;
  margin-top: 15px;

  .his_ul {
    .his_single {
      margin-bottom: 26px;

      .his_right {
        width: 100%;

        .his_r_h5 {
          display: flex;
          align-items: center;
          position: relative;

          .his_h_icon {
            display: inline-block;
            width: 0.24rem;
            height: 0.24rem;
            line-height: 0.24rem;
            text-align: center;
            border-radius: 100%;
            background-color: $main_theme_color;
            color: #333333;
            font-size: 0.12rem;
          }

          .note_name {
            color: #ffffff;
            font-size: 18px;
            margin-left: 10px;
          }

          .present_scheme {
            color: $main_theme_color;
            position: absolute;
            top: 0;
            right: 0;
          }
        }

        .create_time {
          color: #ffffff;
          font-size: 0.14rem;
          margin-left: 0.35rem;
          margin-top: 0.04rem;
        }

        .his_r_box {
          padding: 0.1rem 0 0.16rem 0.35rem;
          color: #ffffff;

          .his_r_box_bc {
            padding: 16px 16px 16px 16px;
            background: #58595a;
            border-radius: 4px;
          }

          .his_r_box_bc_old {
            background: #f4f4f4;
          }

          .wrap_suggest {
            background: #454647;
            padding: 10px;
            border-radius: 4px;
          }

          .wrap_suggest_old {
            background: #f4f4f4;
          }

          .doctor_opinion {
            font-size: 16px;
            font-weight: 500;
            line-height: 0.22rem;
            margin-bottom: 0.04rem;
            opacity: 0.64;
          }

          .special_explain {
            font-size: 16px;
            font-weight: 500;
            word-break: break-all;
            line-height: 0.22rem;
          }

          .special_explain_old {
            color: $main_theme_color_333;
            background: #f4f4f4;
          }

          .suggest {
            font-size: 0.16rem;
            line-height: 22px;
            font-weight: 500;
            margin-bottom: 0.04rem;
            opacity: 0.64;
          }

          .honorific_doctor {
            font-size: 0.16rem;
            margin-bottom: 0.1rem;
            line-height: 0.22rem;
            word-wrap: break-word;
          }

          .honorific_doctor_old {
            background: #f4f4f4;
            color: $main_theme_color_333;
          }

          .his_r_p {
            color: #999999;
            font-size: 0.16rem;
            margin-bottom: 0.14rem;
          }

          .his_con {
            margin-bottom: 0.19rem;
            position: relative;

            .his_c_txt {
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              line-height: 0.3rem;
              color: #999999;
              font-size: 0.16rem;
            }

            .his_c_sp {
              position: absolute;
              right: 0;
              bottom: 0;
              transform: translate(110%, -50%);
              color: $main_theme_color;
              cursor: pointer;
            }
          }

          .his_r__yj {
            width: 100%;
            font-size: 0.14rem;
            display: flex;
            border-radius: 4px;
          }

          .his_r__yj_old {
            color: #333333;
            background: #f4f4f4;
          }

          .yj_left {
            min-width: 100%;
            line-height: 0.2rem;

            .yj_left_p {
              .operator_node {
                flex: 1;
                opacity: 0.64;
              }

              .operator_create_date_time {
                opacity: 0.4;
              }
            }

            .yj_left_suggest {
              word-break: break-all;
              white-space: pre-wrap;
              text-indent: 0;
            }
          }

          .yj_right {
            flex: 1;

            .yj_single {
              width: 3rem;
              word-wrap: break-word;
              word-break: normal;
              line-height: 0.3rem;
              color: #999999;
              font-size: 0.16rem;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }

      &:last-child .his_r_box {
        border: none;
      }
    }
  }

  .no_his {
    padding: 0.87rem 0 1.04rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: $main_theme_color_333;

    .no_his_pic {
      width: 2.04rem;
      height: 1.28rem;
      margin-bottom: 0.27rem;
      background-size: 100% 100%;
    }

    .yellow_pic {
      background-image: url("../../../../../common/imgs/3d/pro_his_empty.png");
    }

    .blue_pic {
      background-image: url("../../../../../common/imgs/3d/blue_web_doc_no_proposal.png");
    }

    .purple_pic {
      background-image: url("../../../../../common/imgs/3d/purple_web_doc_no_proposal.png");
    }
  }
}
</style>
